import { Flex } from 'components';
import { BaseScreenLayout } from 'components/Layouts/BaseScreenLayout';
import { UserAvatarPlusTimeBadge } from 'components/UserAvatarPlusTimeBadge/UserAvatarPlusTimeBadge';
import React, { ReactElement } from 'react';

export const ZanderDebugScreen = (): ReactElement => {
    return (
        <BaseScreenLayout hideGlance>
            <Flex fullWidth justify='center' style={{ gap: 28, height: '100%' }}>
                <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                    <UserAvatarPlusTimeBadge />
                </Flex>
                <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                    <UserAvatarPlusTimeBadge orientation='right' />
                </Flex>
                <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                    <UserAvatarPlusTimeBadge orientation='top' />
                </Flex>
                <Flex justify='end' align='center' style={{ padding: 16, marginTop: 20 }}>
                    <UserAvatarPlusTimeBadge orientation='bottom' />
                </Flex>
            </Flex>
        </BaseScreenLayout>
    );
};
