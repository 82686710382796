// @ts-strict-ignore
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { AccountPermittedToOptionsLevel, OptionsLevel } from 'phoenix/constants/OptionsLevels';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { useAccountRestricted } from 'phoenix/hooks/useAccountRestricted';
import { useActiveAndPendingAccounts, useRelatedAccounts } from 'phoenix/hooks/useActiveAndPendingAccounts';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { ApiPosition, OptionQuote } from 'phoenix/redux/models';
import { GetPlatform } from 'phoenix/resolvers/Platform';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { IsBetaMaintenanceTime, IsNonEditableTradeTime, IsWeekend, QuoteAttribute } from 'phoenix/util';
import { getCannotSubmitReason, getIsOutsideOffshoreTradingHours } from 'phoenix/util/Trading/PreTradeValidationHelpers';
import { XS } from 'phoenix/xstream/XS';
import { useMemo } from 'react';
import { UseAuthorizationSettings } from './UseAuthorizationSettings';
import { useMarketTimeSegmentV2 } from './useMarketTimeSegment';
import useOptionsOrderPermitted, { useOptionsOrderPermittedV2 } from './useOptionsOrderPermitted';

import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

// prettier-ignore
export type TradeCannotSubmitReason = 'loading' | 'type-disabled' | 'not-available' | 'restricted' | 'no-nbbo' | 'options-level'
| 'bad-time' | 'bad-time-holiday' | 'bad-time-weekend' | 'bad-time-equity-options' | 'bad-time-offshore-mutual-funds' | 'bad-time-market-closed' | 'bad-time-to-edit'
| 'gtx-pre-required' | 'gtx-post-required' | 'bad-tif' | 'invalid-input' | 'button-disabled' | 'futures-account' | 'equities-account' | 'adjusted-option-position'
| 'upgrade-options-level' | 'futures-account-onboarding-disabled' | 'beta-maintenance'

export const CallToActionStopReasons = new Set<TradeCannotSubmitReason>(['equities-account', 'futures-account', 'options-level', 'upgrade-options-level']);

export type TradeHooksOptions = {
    isModifyingOrder?: boolean;
    hasModifyingOrderChanged?: boolean;
    useBadTimeReasons?: boolean /** @deprecated */;
    useV2MarketTime?: boolean /** @deprecated We're all using v2 now */;
    withoutRequests?: boolean;
};

export const TradeHooks = {
    useTradeCannotSubmitReason: (trade: ApiTradeRequest, options?: TradeHooksOptions): TradeCannotSubmitReason | undefined => {
        const qsi = trade?.securityId;
        const [marketTimeSegment] = useMarketTimeSegmentV2();
        const assetClass = useAssetClass(qsi);
        const optionsLevelPermitted = useOptionsOrderPermitted({
            accountNumber: trade.accountNumber,
            action: trade.action,
            quantity: trade.quantity,
            symbol: trade.securityId,
            withoutRequests: options?.withoutRequests
        });
        const meta = useSnexStore((s) => s.securities.bySymbol[qsi]?.metadata?.data);
        const relatedAccounts = useRelatedAccounts(trade?.accountNumber);
        const account = relatedAccounts.account || relatedAccounts.pendingAccount;
        const accountRestricted = useAccountRestricted(trade?.accountNumber);
        const { data: variantSettings } = UseAuthorizationSettings();
        const futuresOnboardingFlagEnabled = useFeatureFlag(FeatureFlags.FuturesOnboarding);
        const allowFuturesOnboarding = futuresOnboardingFlagEnabled && variantSettings?.allowedOnboardingTypesForEnvironment.some((x) => x.toLowerCase() === 'futures');
        const offshoreEnabled = useFeatureFlag(FeatureFlags.offshoreMutualFundTrading);
        const platform = GetPlatform();
        const flag = useFeatureFlag(assetClass.flags?.[platform].trade);
        const flaggedOff = assetClass.flags?.[platform].trade && !flag;
        const leg2Meta = useSnexStore((s) => s.securities.bySymbol[trade?.leg2SecurityId]?.metadata?.data);
        const isWeekend = IsWeekend();
        const isBetaMaintenanceTime = IsBetaMaintenanceTime();
        const isNonEditableTradeTime = IsNonEditableTradeTime(trade?.timeInForce);
        const isOutsideOffshoreTradingHours = getIsOutsideOffshoreTradingHours();
        const { accountTypes } = useActiveAndPendingAccounts();
        const { positions = [] as ApiPosition[] } = usePositionsStore();

        // Check if NBBO is available
        const quote: OptionQuote | StandardQuote = assetClass?.derivative === 'option' ? XS.OptionQuotes.use(qsi) : XS.Quotes.use(qsi);

        // XStream will return undefined if quote data is "unusable" (ironically this often happens when no NBBO is available)
        // so make a separate check to see that the quote response exists
        const quoteSnapshot = useSnexStore((s) => s.securityQuote.bySymbol[qsi]);
        const quoteSnapshotLoaded = !!quoteSnapshot && !quoteSnapshot.pristine && !quoteSnapshot.loading
        const quoteLoaded = !!quote || quoteSnapshotLoaded

        const latestPrice = QuoteAttribute.getPrice(quote);
        const nbboUnavailable = useMemo(() => quoteLoaded && !latestPrice, [quoteLoaded, latestPrice]);

        console.log('TRADE HOOKS V1', { qsi, nbboUnavailable, quote, latestPrice });

        const canTradeOptions = useMemo(
            () => !trade?.accountNumber || AccountPermittedToOptionsLevel(trade?.accountNumber, OptionsLevel.CoveredCalls),
            [trade?.accountNumber]
        );
        const isInAlgolia = !!useSecurityMetadataV2.getState().getMetadataBySymbol(trade.securityId)?.isInAlgolia;

        return getCannotSubmitReason({
            account,
            accountRestricted,
            allowFuturesOnboarding,
            assetClass,
            canTradeOptions,
            flaggedOff,
            isBetaMaintenanceTime,
            isNonEditableTradeTime,
            isOutsideOffshoreTradingHours,
            isWeekend,
            marketTimeSegment,
            leg2Meta,
            meta,
            nbboUnavailable,
            offshoreEnabled,
            options,
            optionsLevelPermitted,
            positions,
            trade,
            accountTypes,
            isInAlgolia
        });
    },
    useTradeCannotSubmitReasonV2: (trade: ApiTradeRequest, options?: TradeHooksOptions): TradeCannotSubmitReason | undefined => {
        const qsi = trade?.securityId;
        const [marketTimeSegment] = useMarketTimeSegmentV2();
        const assetClass = useAssetClass(qsi);
        const optionsLevelPermitted = useOptionsOrderPermittedV2({
            accountNumber: trade.accountNumber,
            action: trade.action,
            quantity: trade.quantity,
            symbol: trade.securityId,
            withoutRequests: options?.withoutRequests
        });
        const meta = useSecurityMetadataV2().getMetadataBySymbol(qsi);
        const relatedAccounts = useRelatedAccounts(trade?.accountNumber);
        const account = relatedAccounts.account || relatedAccounts.pendingAccount;
        const accountRestricted = useAccountRestricted(trade?.accountNumber);
        const { data: variantSettings } = UseAuthorizationSettings();
        const futuresOnboardingFlagEnabled = useFeatureFlag(FeatureFlags.FuturesOnboarding);
        const allowFuturesOnboarding = futuresOnboardingFlagEnabled && variantSettings?.allowedOnboardingTypesForEnvironment.some((x) => x.toLowerCase() === 'futures');
        const offshoreEnabled = useFeatureFlag(FeatureFlags.offshoreMutualFundTrading);
        const platform = GetPlatform();
        const flag = useFeatureFlag(assetClass.flags?.[platform].trade);
        const flaggedOff = assetClass.flags?.[platform].trade && !flag;
        const leg2Meta = useSecurityMetadataV2().getMetadataBySymbol(trade?.leg2SecurityId);
        const isWeekend = IsWeekend();
        const isBetaMaintenanceTime = IsBetaMaintenanceTime();
        const isNonEditableTradeTime = IsNonEditableTradeTime(trade?.timeInForce);
        const isOutsideOffshoreTradingHours = getIsOutsideOffshoreTradingHours();
        const { accountTypes } = useActiveAndPendingAccounts();
        const { positions = [] as ApiPosition[] } = usePositionsStore();

          // Check if NBBO is available
          const quote: OptionQuote | StandardQuote = assetClass?.derivative === 'option' ? XS.OptionQuotes.use(qsi) : XS.Quotes.use(qsi);

          // XStream will return undefined if quote data is "unusable" (ironically this often happens when no NBBO is available)
          // so make a separate check to see that the quote response exists
          const quoteSnapshot = useSnexStore((s) => s.securityQuote.bySymbol[qsi]);
          const quoteSnapshotLoaded = !!quoteSnapshot && !quoteSnapshot.pristine && !quoteSnapshot.loading
          const quoteLoaded = !!quote || quoteSnapshotLoaded

          const latestPrice = QuoteAttribute.getPrice(quote);
          const nbboUnavailable = useMemo(() => quoteLoaded && !latestPrice, [quoteLoaded, latestPrice]);

          console.log('TRADE HOOKS V2', { qsi, nbboUnavailable, quote, latestPrice });

        const canTradeOptions = useMemo(
            () => !trade?.accountNumber || AccountPermittedToOptionsLevel(trade?.accountNumber, OptionsLevel.CoveredCalls),
            [trade?.accountNumber]
        );
        const isInAlgolia = !!useSecurityMetadataV2.getState().getMetadataBySymbol(trade.securityId)?.isInAlgolia;

        return getCannotSubmitReason({
            account,
            accountRestricted,
            allowFuturesOnboarding,
            assetClass,
            canTradeOptions,
            flaggedOff,
            isBetaMaintenanceTime,
            isNonEditableTradeTime,
            isOutsideOffshoreTradingHours,
            isWeekend,
            marketTimeSegment,
            leg2Meta,
            meta,
            nbboUnavailable,
            offshoreEnabled,
            options,
            optionsLevelPermitted,
            positions,
            trade,
            accountTypes,
            isInAlgolia
        });
    }
};
