import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountCardList, Flex, LoadingSpinner } from 'components';
import { BlankView } from 'components/BlankView';
import { SnexButton } from 'components/SnexButton/SnexButton';
import { Urls } from 'phoenix/constants';
import { GetClientAccountsAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { useText } from 'phoenix/hooks/UseText';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import animationData from 'phoenix/assets/animations/BankingCalendar.json';
import { BaseScreenLayout } from 'components/Layouts/BaseScreenLayout';
import { Account } from 'phoenix/redux/models';

export const EnableOptionsScreen = React.memo(
    TelemetryProvider(() => {
        const dispatch = useDispatch();
        const text = useText((s) => s.enableOptionsScreen);
        const accounts = useSelector((s: GlobalState) => s.accounts.all);
        const data = accounts?.data || [];

        useEffect(() => {
            dispatch(GetClientAccountsAction());
        }, []);

        const openInNewTab = (a: Account) => {
            const newWindow = window.open(Urls.registration.enableOptions(a.accountNumber), '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        };

        return (
            <BaseScreenLayout hideGlance>
                {data?.length ? (
                    <Flex column align='center' style={{ height: '100%', overflow: 'scroll' }}>
                        <BlankView animationData={animationData} secondaryText={data?.length > 1 ? text.getStartedWithOptions : undefined} text={text.title} />
                        {data?.length > 1 ? (
                            <div style={{ marginBottom: 10, width: '70%', marginTop: 30 }}>
                                <AccountCardList accounts={data} onAccountSelect={openInNewTab} />
                            </div>
                        ) : (
                            <div style={{ marginBottom: 10, width: '30%', marginTop: 30, minWidth: 370 }}>
                                <SnexButton eventTag='Enable Options' flavor='submit' onClick={() => openInNewTab(data[0])}>
                                    Enable Options
                                </SnexButton>
                            </div>
                        )}
                    </Flex>
                ) : (
                    <Flex center style={{ width: '100%', flex: 1, height: '100%' }}>
                        <LoadingSpinner />
                    </Flex>
                )}
            </BaseScreenLayout>
        );
    }, TelemetryCategories.trade)
);
