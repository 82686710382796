import { utcToZonedTime } from 'date-fns-tz';
import { MarketTimeSegmentDefinition } from 'phoenix/constants/AssetTradeability';
import { TradeCannotSubmitReason, TradeHooksOptions } from 'phoenix/hooks/TradingHooks';
import { ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { Account, ApiPosition, TradeAction } from 'phoenix/redux/models';
import { isSecurityMetadataV2, SecurityMetadataMix, SecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { AssetClass as QAssetClass, QualifiedId } from 'phoenix/util/QualifiedId';
import { MarketTimeSegment } from '../DateFormatting';
import { IsOffshoreMutualFundByMetadata, IsOffshoreMutualFundByMetadataV2 } from '../IsMutualFund';
import { GetCanTradeMultiLeg, GetOptionsOpenClose } from '../OptionsHelpers';
import { SearchPositions } from '../Positions/PositionsHelpers';

export type GetCannotSubmitReasonProps = {
    account: Account;
    accountRestricted: boolean;
    allowFuturesOnboarding: boolean;
    assetClass: AssetClass;
    canTradeOptions: boolean;
    flaggedOff: boolean;
    isBetaMaintenanceTime: boolean;
    isNonEditableTradeTime: boolean;
    isOutsideOffshoreTradingHours: boolean;
    isWeekend: boolean;
    leg2Meta?: SecurityMetadataMix;
    marketTimeSegment: MarketTimeSegment;
    meta: SecurityMetadataMix;
    nbboUnavailable: boolean;
    offshoreEnabled: boolean;
    options?: TradeHooksOptions;
    optionsLevelPermitted: boolean;
    positions: ApiPosition[];
    trade: ApiTradeRequest;
    accountTypes: Set<QAssetClass>;
    isInAlgolia?: boolean;
};

export type GetCannotSubmitReasonPropsV2 = Omit<GetCannotSubmitReasonProps, 'meta' | 'leg2Meta'> & {
    meta: SecurityMetadataV2;
    leg2Meta?: SecurityMetadataV2;
};

export function getCannotSubmitReason({
    account,
    accountRestricted,
    allowFuturesOnboarding,
    assetClass,
    canTradeOptions,
    flaggedOff,
    isBetaMaintenanceTime,
    isNonEditableTradeTime,
    isOutsideOffshoreTradingHours,
    isWeekend,
    marketTimeSegment,
    leg2Meta,
    meta,
    nbboUnavailable,
    offshoreEnabled,
    options,
    optionsLevelPermitted,
    positions,
    trade,
    accountTypes,
    isInAlgolia
}: GetCannotSubmitReasonProps): TradeCannotSubmitReason | undefined {
    const { accountNumber, quantity = 0 } = trade;

    console.log('GET CANNOT SUBMIT REASON', {
        account,
        accountRestricted,
        allowFuturesOnboarding,
        assetClass,
        canTradeOptions,
        flaggedOff,
        isBetaMaintenanceTime,
        isNonEditableTradeTime,
        isOutsideOffshoreTradingHours,
        isWeekend,
        marketTimeSegment,
        leg2Meta,
        meta,
        nbboUnavailable,
        offshoreEnabled,
        options,
        optionsLevelPermitted,
        positions,
        trade,
        accountTypes,
        isInAlgolia
    });

    // TODO: Pending dev discussion -  It might start to be a good idea to have an AssetClass impl for offshores. Alternatively, we could start using the tradeable field on SecurityMetadata
    const isOffShoreMf = isSecurityMetadataV2(meta) ? IsOffshoreMutualFundByMetadataV2(meta) : IsOffshoreMutualFundByMetadata(meta);
    const offShoreAllowed = isOffShoreMf ? offshoreEnabled : true;

    const leg1Position = !!SearchPositions({ accountNumber: trade?.accountNumber, positions, qsi: trade?.securityId })?.length;
    const leg2Position = !!SearchPositions({ accountNumber: trade?.accountNumber, positions, qsi: trade?.leg2SecurityId || '' })?.length;
    const leg1Adjusted = isSecurityMetadataV2(meta) ? meta?.option?.isAdjusted : meta?.isAdjusted;
    const leg2Adjusted = isSecurityMetadataV2(leg2Meta) ? leg2Meta?.option?.isAdjusted : leg2Meta?.isAdjusted;
    const cannotTradeAdjusted = (leg1Adjusted && !leg1Position) || (leg2Adjusted && !leg2Position);
    const tProfile = assetClass?.tradeability;

    const hasFuturesAccount = accountTypes.has('futures');
    const hasEquitiesAccount = accountTypes.has('equities');

    const canTradeFutures = QualifiedId.Class(account?.accountNumber) === 'futures';
    const canTradeEquities = QualifiedId.Class(account?.accountNumber) === 'equities';

    // This is a prelimary check, if you don't have an account of the assetClass you're viewing, you should be invited to open an account.
    if ((tProfile.requiresFuturesAccount && !hasFuturesAccount) || (!hasFuturesAccount && assetClass?.family === 'futures'))
        return allowFuturesOnboarding ? 'futures-account' : 'futures-account-onboarding-disabled';

    if ((tProfile.requiresEquitiesAccount && !hasEquitiesAccount) || (!hasEquitiesAccount && assetClass?.family === 'equities')) return 'equities-account';

    /* this check needs to occur first */
    const cannotTradePrefixes = ['B', 'C']; /* this is temp for a hotfix until we can refactor correctly */

    /* verify that symbol is tradeable via algolia */
    /* only perform for offshore mutual funds */
    if (isOffShoreMf && !isInAlgolia) return 'not-available';

    const qsi = isSecurityMetadataV2(meta) ? meta?.qsi : meta?.symbol;
    if (qsi && (!offShoreAllowed || cannotTradePrefixes.includes(qsi?.split?.(':')?.[0]))) return 'not-available';
    if (isBetaMaintenanceTime && tProfile.schedule.betaMaintenance?.disabled) return 'beta-maintenance';

    if (tProfile.nbboRequired && nbboUnavailable) return 'no-nbbo';

    if (!quantity || !accountNumber) return 'invalid-input';
    if (isOffShoreMf && isOutsideOffshoreTradingHours) return 'bad-time-offshore-mutual-funds';
    if (cannotTradeAdjusted) return 'adjusted-option-position';

    // Check that it's the right time of day
    let timingReason: TradeCannotSubmitReason | undefined;
    const canTradePerSchedule = (def: MarketTimeSegmentDefinition | undefined): TradeCannotSubmitReason | undefined => {
        if (!def) return;
        if (def.disabled) return 'bad-time';
        if (!def?.timesInForce?.length) return; // If not disabled and no TIFs defined, then you can trade it
        if (!def.timesInForce.includes(trade?.timeInForce)) {
            if (['GTXPost', 'NTE'].includes(def.timesInForce[0])) return 'gtx-post-required';
            else return 'bad-tif';
        }
    };

    // Cautionary check for the case where the user is able to select an account of the wrong type.
    if ((tProfile.requiresFuturesAccount && !canTradeFutures) || (!canTradeFutures && assetClass?.family === 'futures'))
        return allowFuturesOnboarding ? 'futures-account' : 'futures-account-onboarding-disabled';

    if ((tProfile.requiresEquitiesAccount && !canTradeEquities) || (!canTradeEquities && assetClass?.family === 'equities')) return 'equities-account';

    if (options?.isModifyingOrder && options?.hasModifyingOrderChanged !== undefined && !options?.hasModifyingOrderChanged) return 'invalid-input';

    // TODO: There is a specific timing requirement for pre-market equities orders: they cannot be traded < 5min from market open
    // This should probably be handled in the asset class interface
    if (assetClass.family === 'equities' && options?.isModifyingOrder && isNonEditableTradeTime) return 'bad-time-to-edit';

    if (isBetaMaintenanceTime) timingReason = canTradePerSchedule(tProfile.schedule.betaMaintenance);
    else if (isWeekend) timingReason = canTradePerSchedule(tProfile.schedule.weekends);
    else {
        // prettier-ignore
        switch (marketTimeSegment) {
            case 'closed': timingReason = canTradePerSchedule(tProfile.schedule.marketClosed); break;
            case 'holiday': timingReason = canTradePerSchedule(tProfile.schedule.holidays); break;
            case 'premarket': timingReason = canTradePerSchedule(tProfile.schedule.premarket); break;
            case 'postmarket': timingReason = canTradePerSchedule(tProfile.schedule.postmarket); break;
            case 'open': timingReason = canTradePerSchedule(tProfile.schedule.marketOpen); break;
            case 'loading': timingReason = 'loading'; break;
            default: break;
        }
    }

    if (timingReason) return timingReason;

    // Check various profile-related stuff
    if (flaggedOff) return 'type-disabled';
    if (tProfile.accountMustBeUnrestricted && accountRestricted) return 'restricted';
    if (tProfile.requiresOptionPermission && !canTradeOptions) return 'options-level';

    // Check trade validity
    if (!!trade.accountNumber && tProfile.requiresOptionPermission) {
        if (trade.leg2SecurityId && trade.leg2Quantity) {
            const openClose = GetOptionsOpenClose({ positions, selectedAccountNumber: trade?.accountNumber, symbol: trade?.securityId, tradeAction: trade?.action });
            const leg2OpenClose = GetOptionsOpenClose({
                positions,
                selectedAccountNumber: trade?.accountNumber,
                symbol: trade?.leg2SecurityId,
                tradeAction: trade?.leg2Action as TradeAction
            });

            const canTradeMultiLeg = GetCanTradeMultiLeg({
                optionsLevel: account?.optionsLevel,
                tradesWithOpenClose: [
                    { openClose, quantity: trade?.quantity, tradeAction: trade?.action as TradeAction },
                    { openClose: leg2OpenClose, quantity: trade?.leg2Quantity, tradeAction: trade?.leg2Action as TradeAction }
                ]
            });

            if (!canTradeMultiLeg) return 'upgrade-options-level';
        }
        if (!optionsLevelPermitted && !(trade.leg2SecurityId && trade.leg2Quantity)) return 'upgrade-options-level';
    }
    const oType = trade?.orderType;
    const tif = !!trade?.timeInForce;
    const orderPrice = assetClass.negativePriceAllowed ? !!trade?.orderPrice : trade?.orderPrice && trade.orderPrice > 0;
    const stopLimitPrice = assetClass.negativePriceAllowed ? !!trade?.orderPrice : trade?.stopLimitPrice && trade?.stopLimitPrice > 0;

    if (['limit', 'stop'].includes(oType) && !(tif && orderPrice)) return 'invalid-input';
    if (oType === 'stoplimit' && !(tif && orderPrice && stopLimitPrice)) return 'invalid-input';
    return undefined;
}

export function getIsOutsideOffshoreTradingHours(): boolean {
    const now = new Date();
    const timeZone = 'America/New_York';
    const easternNow = utcToZonedTime(now, timeZone);

    const hour = easternNow.getHours();

    if (hour < 2) {
        return true;
    }

    return false;
}
